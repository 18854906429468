<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <tableMemoCauses :memoCauses="memoCauses" @editForm="editForm" />
    </div>
    <createMemoCauses
      :memoType="memoType"
      :memoStatus="memoStatus"
      @refresh="refresh"
    />
    <editMemoCauses
      :memoType="memoType"
      :memoStatus="memoStatus"
      @refresh="refresh"
      :id="id"
    />
  </div>
</template>

<script>
import tableMemoCauses from "@/views/component/Table/tableMemoCauses.vue";
import createMemoCauses from "@/views/component/Modal/ModalMemoCauses/createMemoCauses.vue";
import editMemoCauses from "@/views/component/Modal/ModalMemoCauses/editMemoCauses.vue";
export default {
  components: {
    tableMemoCauses,
    createMemoCauses,
    editMemoCauses,
  },
  data() {
    return {
      showPreloader: false,
      id: null,
      memoCauses: [],
      memoType: [],
      memoStatus: [],
    };
  },
  mounted() {
    this.refresh();
    this.getMemoType();
    this.getMemoStatus();
  },
  methods: {
    refresh() {
      let params = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      this.showPreloader = true;
      this.$http.get(`${this.$route.name}`, { params: params }).then((res) => {
        this.memoCauses = res.data;
        this.showPreloader = false;
        this.$store.commit("pageData/setdataCount", this.memoCauses.length);
        this.$store.commit("REFRESH_DATA", false);
      });
    },
    getMemoType() {
      this.$http.get(`memo-types`).then((res) => {
        this.memoType = res.data;
      });
    },
    getMemoStatus() {
      this.$http.get(`memo-status`).then((res) => {
        this.memoStatus = res.data;
      });
    },
    editForm(item) {
      this.id = item.id;
      setTimeout(() => {
        this.$bvModal.show("editMemoCauses");
      }, 0);
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData() {
      this.refresh();
    },
  },
};
</script>

<style lang="scss" scoped></style>
