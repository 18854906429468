import { render, staticRenderFns } from "./editMemoCauses.vue?vue&type=template&id=006ae27b&scoped=true&"
import script from "./editMemoCauses.vue?vue&type=script&lang=js&"
export * from "./editMemoCauses.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "006ae27b",
  null
  
)

export default component.exports