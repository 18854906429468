<template>
  <div>
    <b-modal
      :id="$route.name + 'CreateModal'"
      title="Создание причины (CЗ)"
      size="md"
      hide-footer
      no-enforce-focus
      :no-close-on-backdrop="true"
      @close="clearData"
    >
      <b-row>
        <b-col cols="12">
          <label for="">Наименование</label>
          <b-input v-model="memoCause.name" placeholder="Наименование" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <label for="">Тип</label>
          <v-select
            :reduce="(options) => options.id"
            :options="memoType"
            label="name"
            v-model="memoCause.memo_type_id"
          ></v-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <label for="">Статус причины</label>
          <v-select
            :reduce="(options) => options.id"
            :options="memoStatus"
            label="name"
            v-model="memoCause.memo_status_id"
          ></v-select>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-checkbox
            v-model="memoCause.is_active"
            class="custom-control-primary"
          >
            {{ memoCause.is_active ? "Не блокирован" : "Блокирован" }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-between">
          <b-button
            :disabled="submitButtonDisabled"
            class="mt-2 col-md-5"
            variant="primary"
            @click="createMemoCauses"
          >
            Добавить
          </b-button>
          <b-button
            class="mt-2 col-md-5"
            variant="secondary"
            @click="clearData"
          >
            Отмена
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    vSelect,
  },
  props: ["memoType", "memoStatus"],
  data() {
    return {
      memoCause: {
        name: "",
        memo_type_id: "",
        memo_status_id: "",
        is_active: false,
      },
      submitButtonDisabled: false,
    };
  },
  methods: {
    createMemoCauses() {
      this.memoCause.is_active = this.memoCause.is_active ? 1 : 0;
      this.submitButtonDisabled = true;
      this.$http
        .post("memo-causes", this.memoCause)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.$emit("refresh");
          this.clearData();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: error.response.data.errors,
            },
          });
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
    },
    clearData() {
      this.$bvModal.hide(this.$route.name + "CreateModal");
      this.memoCause = {
        name: "",
        memo_type_id: "",
        memo_status_id: "",
        is_active: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
