<template>
  <div>
    <b-modal
      id="editMemoCauses"
      title="Редактирование причины (CЗ)"
      size="md"
      hide-footer
      no-enforce-focus
      :no-close-on-backdrop="true"
      @show="getMemoCauses"
      @close="clearData"
    >
      <b-row>
        <b-col cols="12">
          <label for="">Наименование</label>
          <b-input v-model="memoCause.name" placeholder="Наименование" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <label for="">Тип</label>
          <v-select
            :reduce="(options) => options.id"
            :options="memoType"
            label="name"
            v-model="memoCause.memo_type_id"
          ></v-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <label for="">Статус причины</label>
          <v-select
            :reduce="(options) => options.id"
            :options="memoStatus"
            label="name"
            v-model="memoCause.memo_status_id"
          ></v-select>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-checkbox
            v-model="memoCause.is_active"
            class="custom-control-primary"
          >
            {{ memoCause.is_active ? "Не блокирован" : "Блокирован" }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-between">
          <b-button
            :disabled="submitButtonDisabled"
            class="mt-2 col-md-5"
            variant="primary"
            @click="editMemoCauses"
          >
            Добавить
          </b-button>
          <b-button
            class="mt-2 col-md-5"
            variant="secondary"
            @click="clearData"
          >
            Отмена
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    vSelect,
  },
  props: ["memoType", "memoStatus", "id"],
  data() {
    return {
      memoCause: {},
      submitButtonDisabled: false,
    };
  },
  methods: {
    getMemoCauses() {
      this.$http.get(`memo-causes/${this.id}/edit`).then((res) => {
        this.memoCause = res.data;
        this.memoCause.is_active = this.memoCause.is_active == 1 ? true : false;
      });
    },
    clearData() {
      this.$bvModal.hide("editMemoCauses");
      this.memoCause = {};
    },
    editMemoCauses() {
      this.memoCause.is_active = this.memoCause.is_active ? 1 : 0;
      this.submitButtonDisabled = true;
      this.$http
        .patch(`memo-causes/${this.id}`, this.memoCause)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: res.data.message,
            },
          });
          this.$emit("refresh");
          this.clearData();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ошибка!",
              icon: "AlertCircleIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
